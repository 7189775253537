// import React from 'react';
// import { Link } from 'react-router-dom';
// import UserService from '../service/UserService';

// function Navbar() {
//     const isAuthenticated = UserService.isAuthenticated();
//     const isAdmin = UserService.isAdmin();



//     const handleLogout = () => {
//         const confirmDelete = window.confirm('Are you sure you want to logout this user?');
//         if (confirmDelete) {
//             UserService.logout();
//         }
//     };


//     return (
//         <nav class="navbar">
//             <ul>
//                  <li><Link to="/buy">Kup</Link></li>
//                  <li><Link to="/rent">Wynajmij</Link></li>
//                 {isAuthenticated && <li><Link to="/profile">Profile</Link></li>}
//                 {isAdmin && <li><Link to="/admin/user-management">Zarządzanie użytkownikami</Link></li>}
//                 {isAdmin && <li><Link to="/admin/skin-management">Zarządzanie skinami</Link></li>}
//                 {isAuthenticated && <li><Link to="/" onClick={handleLogout}>Logout</Link></li>}
//             </ul>
//         </nav>
        
//     );
// }

// export default Navbar;

import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import UserService from '../service/UserService';

function Navbar() {
    const isAuthenticated = UserService.isAuthenticated();
    const isAdmin = UserService.isAdmin();
    const location = useLocation(); // hook do pobrania bieżącej lokalizacji

    // Logowanie bieżącej lokalizacji dla diagnostyki
    console.log('Current path:', location.pathname);

    const handleLogout = () => {
        const confirmDelete = window.confirm('Are you sure you want to logout this user?');
        if (confirmDelete) {
            UserService.logout();
        }
    };

    // Funkcja sprawdzająca, czy dany link jest aktualnie aktywny
    const isActive = (path) => location.pathname === path;

    return (
        <nav class="navbar">
            <ul>
                <li class={isActive('/buy') ? 'active' : ''}>
                    <Link to="/buy">Sprawdź wysokość rat</Link>
                </li>
                <li class={isActive('/rent') ? 'active' : ''}>
                    <Link to="/rent">Sprawdź cenę wynajmu</Link>
                </li>
                <li class={isActive('/information') ? 'active' : ''}>
                    <Link to="/information">Informacja</Link>
                </li>
                {/* {isAuthenticated && (
                    <li class={isActive('/profile') ? 'active' : ''}>
                        <Link to="/profile">Profile</Link>
                    </li>
                )} */}
                {/* {isAdmin && (
                    <li class={isActive('/admin/user-management') ? 'active' : ''}>
                        <Link to="/admin/user-management">Zarządzanie użytkownikami</Link>
                    </li>
                )} */}
                {isAdmin && (
                    <li class={isActive('/admin/skin-management') ? 'active' : ''}>
                        <Link to="/admin/skin-management">Zarządzanie skinami</Link>
                    </li>
                )}
                {isAuthenticated && (
                    <li>
                        <Link to="/" onClick={handleLogout}>Logout</Link>
                    </li>
                )}
            </ul>
        </nav>
    );
}

export default Navbar;


