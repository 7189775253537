import ja from '../../images/ja.png';

function InformationPage() {
    return (
<body class="body">


<div class="container">
<h2 className="h2" style={{ textAlign: 'center' }}>Witaj!</h2>



    <div class="image-container">
      <img class="image-resize" src={ja} alt="Logo" />
    </div>
    <p class="p">Nazywam się <span class="highlight">Konrad „kodiQ” Kunicki</span>.  Jestem jednym z najbardziej rozpoznawalnych traderów skinów cs2 w Polsce. Zajmuję się tym od 2021 roku. Napisałem tę stronę, aby ułatwić nam deala. Możesz na niej sprawdzić cenę zakupu lub wynajmu skina, a następnie skontaktować się ze mną przez Messenger w celu sfinalizowania transakcji <span>&#128512;</span> Zaufało mi ponad 500 zadowolonych klientów. Znajdziesz ich tutaj: <a href="https://www.facebook.com/share/p/taMdA18p5hAYFPKr/" target="_blank" class="a-link">Legit Check  </a>.</p>
    {/* <h3 class="h2">O mnie:</h3> */}
    {/* <p class="p">Jestem jednym z najbardziej rozpoznawalnych handlarzy skinów cs2 w Polsce. Zajmuję się handlem skinów od 3 lat. Zaufało mi ponad 500 zadowolonych klientów. Znajdziesz ich tutaj: <a href="https://www.facebook.com/share/p/taMdA18p5hAYFPKr/" target="_blank" class="a-link">Legit Check  </a>. </p> */}

    
    <h3 class="h2">Moje usługi </h3>
    <ul >
        <li>Wynajem</li>
        <li>Sprzedaż / Sprzedaż na raty</li>
        <li>Kupno/ Kupno na tradebanie {"(mogę zapłacić pierwszy przy skinach powyżej 2000 PLN)"}</li>
        <li>Wymiana / Wymiana z dopłatą</li>
    </ul>
    <h3 class="h2">Jak wynająć skina?</h3>
    <p class="p">Napisz do mnie na Facebooku, który skin chcesz wynająć i na ile dni. Koszt wynajmu sprawdzisz na tej stronie.</p>

    <h3 class="h2">Jak kupić skina na raty?</h3>
    <p class="p">Napisz do mnie na Facebooku, który skin chcesz kupić na raty. Podaj liczbę rat oraz odstęp czasu pomiędzy ratami. Możesz wybrać dowolne parametry. Koszt rat również sprawdzisz na tej stronie.</p>
    <h3 class="h2">Wynajem i Kupno Skina CS2 na Raty</h3>
    <p class="p">Przy wynajmie i kupnie na raty podpisujemy umowę podpisem zaufanym.</p>
    {/* <p class="p">Po podpisaniu umowy sprawdzam Twoją wiarygodność finansową, między innymi czy nie masz długów. Nie współpracuję z osobami, które mają długi.</p> */}

    <div class="link-section">
        <h3 class="h2">Jak podpisać umowę podpisem zaufanym?</h3>
        <p class="p">Zamieszczam krótki filmik z instrukcją, jak to zrobić:</p>

            <a href="https://www.youtube.com/watch?v=YoTwOnfnRLk&ab_channel=inFakt.pl" target="_blank" class="a-link">Filmik instruktażowy</a>
        
        <p class="p">Link do podpisywania:</p>
        <a href="https://moj.gov.pl/nforms/signer/upload?xFormsAppName=SIGNER" target="_blank" class="a-link">Kliknij tutaj, aby podpisać umowę podpisem zaufanym</a>
    </div>

    <h3 class="h2">Dane niezbędne do podpisania umowy:</h3>
    <ul >
        <li>Twoje imię i nazwisko</li>
        <li>Twój adres zamieszkania</li>
        <li>Tradelink</li>
    </ul>

    <h3 class="h2">Wiarygodność finansowa</h3>
    <p class="p">Nie współpracuję z osobami, które mają długi. Po podpisaniu umowy zostanie sprawdzona Twoja wiarygodność finansowa. Zgodnie z prawem, do jej sprawdzenia będzie potrzebna Twoja zgoda. Formularz zgody zostanie wysłany na Twój e-mail.</p>
    <p class="p">Twoje dane niezbędne do wygenerowania zgody:</p>


    <ul>
        <li>Numer telefonu</li>
        <li>Email</li>
        <li>Numer dowodu osobistego</li>
    </ul>

    <h3 class="h2">Czy możesz wynająć skina, jeśli nie jesteś osobą pełnoletnią?</h3>
    <p class="p">Tak, o ile umowę podpisze Twój rodzic, brat lub znajomy.</p>



    <h3 class="h2">Linki</h3>
    <ul>
        <li class="list-item2"><a href="https://www.facebook.com/profile.php?id=100008014602450" target="_blank" class="a-link">Profil Facebook</a></li>
        <li class="list-item2"><a href="https://www.facebook.com/share/p/taMdA18p5hAYFPKr/" target="_blank" class="a-link">Legit Check</a></li>
        <li class="list-item2"><a href="https://steamcommunity.com/id/kodiQTrading/" target="_blank" class="a-link">Profil Steam</a></li>
        <li class="list-item2"><a href="https://steamcommunity.com/tradeoffer/new/?partner=303971918&token=9hIhf6li" target="_blank" class="a-link">Tradelink</a></li>
    </ul>
</div>

{/* <footer class="footer">
    <p>Wszystkie prawa zastrzeżone &copy; 2024</p>
</footer> */}

</body>
    );

}
export default InformationPage;
